/* TrafficStats.css */
.traffic-stats {
  background-color: #f9f9f9; /* Subtle background for the stats */
  padding: 20px; /* Slightly less padding for a smaller area */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0,0,0,0.12); /* Slightly smaller shadow for a subtle effect */
  margin: 20px auto; /* Center the component in the container */
  width: calc(100% - 40px); /* Adjust width, subtracting the total padding */
  max-width: 700px; /* Smaller max width */
}

.stat-item {
  display: flex;
  align-items: center;
  padding: 10px 0; /* Less vertical padding for each item */
  border-bottom: 1px solid #e0e0e0; /* Separator for items */
}

.stat-item:last-child {
  border-bottom: none; /* No border for the last item */
}

.stat-icon {
  width: 25px; /* Slightly smaller icon size */
  height: auto;
  margin-right: 8px;
}

.stat-item span {
  font-size: 16px; /* Slightly smaller font size */
  font-weight: 500; /* Medium font weight */
  color: #333; /* Darker font color for contrast */
}

/* Dark mode specific styles */
body.dark-mode .traffic-stats {
  background-color: #2c2c2c; /* Darker background for dark mode */
  color: white; /* Ensure all text is white */
}

.dark-mode .stat-item span {
  color: white; /* Text color for stats */
}

.dark-mode .add-to-list-button {
  background-color: #218838; /* Adjust if you have a specific color in mind for dark mode */
}

.dark-mode .add-to-list-button:hover {
  background-color: #1e7a2e; /* Darker green on hover, adjust as needed */
}