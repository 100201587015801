/* SavedLists.css */
.saved-lists {
  background-color: #f7f7f7;
  padding: 20px;
  margin: 0 auto; /* Centering it horizontally */
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px; /* Set a fixed width to maintain consistency */
  text-align: center;
}

.saved-lists h2 {
  color: #d12317;
  margin-bottom: 20px;
}

.saved-lists ul {
  padding: 0;
  margin-bottom: 20px;
  list-style-type: none; /* Removes bullet points */
}

.saved-lists li {
  background-color: #f8f9fa;
  margin-bottom: 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.saved-lists li a {
  display: block;
  padding: 15px; /* Increased padding for larger click area */
  font-size: 18px; /* Bigger text for list items */
  color: #333;
  font-weight: bold; /* Make the list names bold */
  position: relative; /* Required for the after element to position itself */
  text-decoration: none;
}

.saved-lists li a:hover {
  transform: translateY(-3px); /* More pronounced pop effect */
  background-color: #e9ecef;
}

.saved-lists li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #d12317;
  transition: width .5s ease;
  -webkit-transition: width .5s ease;
}

.saved-lists li a:hover::after {
  width: 100%;
  left: 0;
  background-color: #d12317;
}

/* Styles for creating a new list */
.new-list-input {
  width: calc(100% - 24px); /* Adjust width considering padding */
  padding: 15px; /* Increased padding for a larger input box */
  margin-bottom: 10px; /* Space between input and button */
  border-radius: 20px; /* Rounder corners */
  border: 1px solid #ccc;
  font-size: 16px; /* Bigger font size for visibility */
  box-sizing: border-box; /* To include padding in width */
}

.create-list-button {
  width: 100%; /* Button takes full width */
  padding: 15px;
  font-size: 16px;
  background-color: #d12317;
  border: none;
  border-radius: 20px; /* Match the input box roundness */
  color: white;
  cursor: pointer;
  transition: background-color .3s, transform .3s;
}

.create-list-button:hover {
  background-color: #a71d12; /* Darker red on hover */
  transform: translateY(-3px); /* Pop effect on hover */
}

/* Error message */
.error {
  color: #dc3545;
  margin-top: 10px;
}

.new-list-input {
  width: 100%; /* Full width of the parent */
  padding: 15px; /* Larger padding for a bigger input box */
  margin-bottom: 15px; /* More space below */
  border-radius: 20px; /* More rounded borders */
  border: 1px solid #ccc; /* Standard border */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

body.dark-mode .saved-lists li a {
  color: #ffffff; /* White color for dark mode */
}

body.dark-mode .saved-lists {
  background-color: #2c2c2c; /* Darker background for the entire component */
}

body.dark-mode .saved-lists li {
  background-color: #2c2c2c; /* Darker background for list items */
}

body.dark-mode .new-list-input {
  background-color: #434343; /* Dark background for input */
  color: #FFF; /* White text for dark mode */
  border-color: #555; /* Slightly lighter border for visibility */
}

body.dark-mode .create-list-button {
  background-color: #a71d12; /* Dark mode button color */
}

body.dark-mode .saved-lists h2,
body.dark-mode .error {
  color: #FFF; /* Ensuring headers and error messages are readable in dark mode */
}

body.dark-mode .saved-lists li a:hover {
  background-color: #505050; /* A lighter dark background for hover state */
}

/* If you have specific styles for placeholders, you might also want to adjust them */
body.dark-mode .new-list-input::placeholder {
  color: #BDBDBD; /* Lighter placeholder text for dark mode */
}

.saved-lists li{
  background-color: #e5d9d9;
}

body.dark-mode .saved-lists li{
  background-color: #795a5a;
}