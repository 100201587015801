.reset-password-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.reset-password-page h2 {
  color: #d12317;
  margin-bottom: 20px;
  text-align: center;
}

.reset-password-page .error {
  color: #dc3545;
  margin-bottom: 10px;
  text-align: center;
}

.reset-password-page .success {
  color: #28a745;
  margin-bottom: 10px;
  text-align: center;
}

.reset-password-page form {
  display: flex;
  flex-direction: column;
}

.reset-password-page label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.reset-password-page input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px; /* Add margin bottom for space between input and next label */
  width: 80%;
}

.reset-password-page button {
  padding: 10px;
  font-size: 16px;
  background-color: #d12317;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.reset-password-page button:hover {
  background-color: #a71d12;
  transform: translateY(-3px);
}

/* Dark mode styles */
body.dark-mode .reset-password-page {
  background-color: #333;
  color: #f9f9f9;
}

body.dark-mode .reset-password-page label {
  color: #f9f9f9;
}

body.dark-mode .reset-password-page input {
  background-color: #434343;
  color: #FFF;
  border-color: #555;
}

body.dark-mode .reset-password-page button {
  background-color: #a71d12;
}

body.dark-mode .reset-password-page button:hover {
  background-color: #871010;
}

body.dark-mode .reset-password-page .error,
body.dark-mode .reset-password-page .success {
  color: #FFF;
}
