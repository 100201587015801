.most-searched {
  background: linear-gradient(to right, #eeeeee, #eeeeee); /* Subtle gradient background */
  padding: 20px;
  margin: 20px auto; /* Centering it horizontally with auto margins */
  margin-right: 11.5%;
  border: 1px solid #ccc; /* This adds a border */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Deeper shadow for 3D effect */
  text-align: center; /* Center the text */
  max-width: 400px; /* Set a max-width for large screens */
  border-color: #b70d0d;
  width: 100%;
}

.most-searched h2 {
  color: #333; /* Darker color for the title */
  font-size: 1.25rem; /* Slightly larger font size */
  margin-bottom: .5rem; /* Adjusted bottom margin */
}

.most-searched p {
  color: #b70d0d; /* Darker color for better readability */
  font-weight: bold; /* Make the text bold */
  font-size: 1.5rem; /* Larger font size for emphasis */
  margin-top: .5rem; /* Adjusted top margin */
}

body.dark-mode .most-searched p {
  color: #b70d0d;
}
