.footer {
  background-color: #f0f0f0; /* Adjust the color to fit your design */
  padding: 20px;
  text-align: center;
}

.footer-content p {
  margin: 5px 0; /* Spacing between text lines */
  color: #333; /* Adjust text color as needed */
}

.footer a {
  color: #007bff; /* Adjust link color as needed */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Responsive adjustments if necessary */
@media (max-width: 768px) {
  .footer-content {
    font-size: 14px;
  }
}

body.dark-mode .footer.footer-content p{
  color:black;
}

body.dark-mode .footer a{
  color: #1691f8
}